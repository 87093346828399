import React from "react";
import UserAdmin from "../Components/Dashboard/UserAdmin";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
import {CSVLink} from "react-csv";

const URL = "https://cors-anywhere.herokuapp.com/https://dev.royaltrading.world/api/app/network/referred-members";

const DirectReferrals = () => {
    const [item, setItem] = useState([]);
    const [filteredItem, setFilteredItem] = useState([]);
    const [access_token, setaccess_token] = useState('');

    const getItem = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }

    const getdata = async () => {
        let response = await getItem();
        console.log(response);
        setItem(response.data.users.data);
        setFilteredItem(response.data.users.data);
        console.log(item)
    }

    const columns = [
        {
            name: <h6>Username</h6>,                   
            selector: row => row.username,               
            sortable: true,
        },
        {
            name: <h6>Name</h6>,
            selector: row => row.first_name+" "+row.last_name,                
            sortable: true,
        },
        {
            name: <h6>Total Subscription</h6>,
            selector: row => "$  "+row.profile.total_subscription,                
            sortable: true,
        },
        {
            name:<h6>Joined On</h6>,
            selector: row => row.created_at,            
            sortable: true,
        },
        {
            name:<h6>Total Business</h6>,
            selector: row => row.profile.business_amount,           
            sortable: true,
        },
        {
            name: <h6>Rank</h6>,
            selector: row => row.rank.name,      
            sortable: true,
        }
    ]

    useEffect(() => {

        // setTimeout(() => {
        //     getdata();
        // }, 1000);

        getdata();

        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);

    }, []);

    // const csvLink = {
    //     filename:"Referrals.csv",
    //     headers:columns,
    //     data:filteredItem
    // }

    return (
        <>
            <UserAdmin
                card2={
                    <div className="col-12 col-md-12 card ml-4 mri mt-4">
                    <DataTable
                        title={<h5 className="mt-4">Direct Referrals</h5>}
                        columns={columns}
                        data={filteredItem}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="600px"
                        selectableRow
                        selectableRowsHighlight
                        highlightOnHover
                        // actions={<CSVLink {...csvLink} className="btn btn-md btn-primary mt-3">Export</CSVLink>}
                        actions={
                            <CSVLink data={item} filename="Direct_Referrals.csv">
                                <button className="btn btn-md btn-primary mt-3">Export</button>
                            </CSVLink>
                        }
                    />
                    </div>
                } />
           
        </>
    )
}
export default DirectReferrals;
