import React from "react";
import UserAdmin from "../Components/Dashboard/UserAdmin";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
import { CSVLink } from "react-csv";

const URL = "https://cors-anywhere.herokuapp.com/https://dev.royaltrading.world/api/app/report/comission";

const Transactions = () => {
    const [item, setItem] = useState([]);
    const [filteredItem, setFilteredItem] = useState([]);
    const [access_token, setaccess_token] = useState('');

    const getItem = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }
    const getdata = async () => {
        let response = await getItem();
        console.log(response);
        setItem(response.data.incomes.data);
        setFilteredItem(response.data.incomes.data);
        console.log(item)

    }

    const columns = [
        {
            name: <h6>Date</h6>,                   
            selector: row =>row.created_at,              
            sortable: true,
        },
        {
            name: <h6>Source</h6>,
            selector: row => row.source,              
            sortable: true,
        },
        {
            name: <h6>Description</h6>,
            selector: row => row.description,              
            sortable: true,
        },
        {
            name: <h6>Amount</h6>,
            selector: row => row.amount,            
            sortable: true,
        },
        {
            name: <h6>Level</h6>,
            selector: row => row.currency,           
            sortable: true,
        },
      
        {
            name: <h6>Status</h6>,
            selector: row => row.level,           
            sortable: true,
        }
    ]
    useEffect(() => {

        if(filteredItem.length === 0 && item.length === 0){
            getdata();
        }

        // setTimeout(() => {
        //     getdata();
        // }, 5000);

        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);

    }, []);

    return (
        <>
        
            <UserAdmin
                card2={
                    <div className="col-12 col-md-12 card ml-4 mri mt-4">
                    <DataTable
                        title={<h5 className="mt-4">Transactions</h5>}
                        columns={columns}
                        data={filteredItem}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="600px"
                        selectableRow
                        selectableRowsHighlight
                        highlightOnHover
                        actions={
                            <CSVLink data={item} filename="Transactions.csv">
                            <button className="btn btn-md btn-primary mt-3">Export</button>
                            </CSVLink>
                        }
                    />
                    </div>
                } 
            />
            
        </>
    )
}
export default Transactions;
