import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const URL ="https://cors-anywhere.herokuapp.com/https://dev.royaltrading.world/api/app/member-rank/details?username=";



const RankDetail = (props) => {
    const [username, setUsername] = useState({});
    const [access_token, setaccess_token] = useState('');

    const user = async (data) => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.post(`${URL}`+ props.username + "&search=true", data, {
                headers: {
                    // "Content-Type": "application/json",
                    "Authorization": "Bearer " + access_token,
                    "Accept": "*/*",
                    'Access-Control-Allow-Methods': 'OPTIONS,POST',
                    'Access-Control-Allow-Credentials': true,
                    'Access-Control-Allow-Origin': '*',
                    'X-Requested-With': '*',
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
                "async": true,
                "crossDomain": true,
                "method": "GET",
            })
            console.log(result)
            return  result.data;
        } catch (error) {
            console.log(error)
        }
    }

    async function sent() {
        let item = {username};
        console.log(item);
       
        let result = await user(item)
        
        if (result.status == true) {
             toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        } else {
            if (result.hasOwnProperty('errors')) {
                if (result.errors.hasOwnProperty('email')) {
                     toast.info(result.errors.email[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                }
            } else {
                 toast.info(result.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }

        }
    }

    return (
        <><ToastContainer />
            <div className="container-fluid mt-5 mb-5">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="card">
                            <div className="card-header">
                            Rank Details
                            </div>
                            <div className=" card-body d-flex">
                                <div className="col-6 ">
                                    <label for="exampleInputEmail1">Username</label>
                                    <input 
                                    className="form-control  mb-3" 
                                    type="text"  
                                    placeholder="Enter Username"
                                    name="username"
                                    onChange={(e) => setUsername(e.target.value)}
                                    />

                                </div>
                                <div className="col-6 mt-3 pt-3">
                                    <button onClick={sent} type="button" className="btn btn-primary btn-block">Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default RankDetail;