import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../css/my.css";
import useClipboard from "react-use-clipboard";
const URL = "https://cors-anywhere.herokuapp.com/https://dev.royaltrading.world/api/app/member/user-token-transaction";
const URL2 = "https://cors-anywhere.herokuapp.com/https://dev.royaltrading.world/api/app/manual/deposit"

const DepositWallet = () => {
    const [item, setItem] = useState([]);
    const [filteredItem, setFilteredItem] = useState([]);
    const [access_token, setaccess_token] = useState('');
    const [usd, SetUsd] = useState();
    const [bnb, SetBnb] = useState();
    const [fixedUsd, SetFixedUsd] = useState(); 
    const [usdInputActive, setUsdInputActive] = useState(false)
    const [bnbInputActive, setBnbInputActive] = useState(false)
    const [transaction_id, SetTransaction_id] = useState("");
    const [isCopied, setCopied] = useClipboard(item?.tokenAddress);

    const postData = async (data) => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.post(`${URL2}`, data, {
                headers: {
                    "Authorization": "Bearer " + access_token,
                    "Accept": "*/*",
                    'Access-Control-Allow-Methods': 'OPTIONS,POST',
                    'Access-Control-Allow-Credentials': true,
                    'Access-Control-Allow-Origin': '*',
                    'X-Requested-With': '*',
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
                "async": true,
                "crossDomain": true,
                "method": "GET",
            })
            console.log(result)
            return result.data;
        } catch (error) {
            console.log(error)
        }
    }

    async function transaction() {
        let item = { transaction_id };
        console.log(transaction_id);

        let result = await postData(item)

        if (result.status == true) {
            toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        } else {
            if (result.hasOwnProperty('errors')) {
                if (result.errors.hasOwnProperty('transaction_id')) {
                    toast.info(result.errors.transaction_id[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } else {
                toast.info(result.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    // const onValueChange = (e) => {
    //     console.log(e.target.name, e.target.value);
    //     SetTransaction_id({ ...transaction_id, [e.target.name]: e.target.value });
    //     console.log(transaction_id);
    // }

    const getData = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            const response = await axios.get(URL,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + access_token
                    }
                })

            setItem(response.data);
            setFilteredItem(response.data.user.profile)

            SetFixedUsd((response.data.cappingbnbPrice).toFixed(4))
            SetBnb((usd * fixedUsd).toFixed(4))

        } catch (error) {
            console.log('error by calling data')
        }
    }

    const USDtoBNB = (event) => {
        console.log("hit USDtoBNB", event.target.value, event.target.value / fixedUsd)
        const usdNumber = event.target.value
        // here we need to set the data for BNB
        SetBnb((usdNumber / fixedUsd).toFixed(4))
    }

    const BNBtoUSD = (event) => {
        console.log("hit BNBtoUSD", event.target.value, event.target.value * fixedUsd)
        const bnbNumber = event.target.value
        // here we need to set the data for USD
        SetUsd((bnbNumber * fixedUsd).toFixed(4))
    }

    const changeActiveStatus = (inputName) => {
        if (inputName === "usdInput") {
            setUsdInputActive(true)
            setBnbInputActive(false)
        }
        if (inputName === "bnbInput") {
            setUsdInputActive(false)
            setBnbInputActive(true)
        }
    }

    useEffect(() => {

        if (item.length === 0 && filteredItem.length === 0) {
            getData();
        }
        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);
    }, []);

    return (
        <>
            <div className="container-fluid mt-3 mb-3">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="card mb-3">
                            <div className="card-header">
                                Deposit Wallet
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">Balance :</h5>
                                <p className="card-text mt-4">${parseFloat(item?.deposit_wallet_balance).toFixed(2) || '0.00'}</p>
                                <a href="/" className="btn btn-primary">Dashboard</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="card">
                            <div className="card-header">
                                Verify Your Transaction
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">Transaction ID :</h5>
                                <form>
                                    <input
                                        class="form-control mb-3"
                                        type="text"
                                        placeholder="Enter transaction id"
                                        name="transaction_id"
                                        onChange={(e) => SetTransaction_id(e.target.value)}
                                    />
                                    <a onClick={transaction} href="#" className="btn btn-primary">Submit</a>
                                    <ToastContainer />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3 mb-3">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="card mb-3">
                            <div className="card-header">
                                Calculator
                            </div>
                            <div className="card-body">
                                <h6 className="card-title">USD :</h6>
                                {
                                    usdInputActive ?
                                        <input
                                            className="form-control mb-3 usd"
                                            type="number"
                                            placeholder="USD Amount"
                                            onChange={((event) => USDtoBNB(event))}
                                        />
                                        :
                                        <input
                                            className="form-control mb-3 usd"
                                            type="number"
                                            placeholder="USD Amount"
                                            onChange={((event) => USDtoBNB(event))}
                                            value={usd}
                                            onClick={() => changeActiveStatus("usdInput")}
                                        />
                                }
                                <h6 className="card-title">BNB :</h6>
                                {
                                    bnbInputActive ?
                                        <input
                                            className="form-control mb-3 bnb"
                                            type="number"
                                            placeholder="BNB Amount"
                                            onChange={((event) => BNBtoUSD(event))}

                                        />
                                        :
                                        <input
                                            className="form-control mb-3 bnb"
                                            type="number"
                                            placeholder="BNB Amount"
                                            onChange={((event) => BNBtoUSD(event))}
                                            value={bnb}
                                            onClick={() => changeActiveStatus("bnbInput")}
                                        />
                                }

                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="card">

                            <div className="card-header">
                                USD Deposit
                            </div>
                            <div className="flex">

                                <div className="col-12 col-md-6 card-body">
                                    <img src={(filteredItem?.two_factor_image_url)} className="qr w-100 ml-1" ></img>
                                </div>

                                <div className="col-12 col-md-6 mt-5 mb-3 hi pb-2 mr-3">
                                    <p style={{ width: "240px" }} className="w-100">{(item?.tokenAddress)}</p>
                                    <button onClick={setCopied} className="btn btn-primary">Copy Address</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DepositWallet;
