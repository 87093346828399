import React from "react";
import "../../css/ReferralLink.css";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
import "../../css/referral.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useClipboard from "react-use-clipboard";

const URL = "https://cors-anywhere.herokuapp.com/https://dev.royaltrading.world/api/app/referral/send-mail";

const ReferralLink = (props) => {
    const [all_data, setAllData] = useState();
    const [access_token, setaccess_token] = useState();
    const [users, setUsers] = useState();
    const [email, setEmail] = useState();
    const [isCopied, setCopied] = useClipboard(props.link);

    const user = async (data) => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.post(`${URL}`, data, {
                headers: {
                    "Authorization": "Bearer " + access_token,
                    "Accept": "*/*",
                    'Access-Control-Allow-Methods': 'OPTIONS,POST',
                    'Access-Control-Allow-Credentials': true,
                    'Access-Control-Allow-Origin': '*',
                    'X-Requested-With': '*',
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
                "async": true,
                "crossDomain": true,
                "method": "GET",
            })
            console.log(result)
            return result.data;
        } catch (error) {
            console.log(error)
        }
    }

    async function sent() {
        let item = { email };
        console.log(item);
        let result = await user(item)

        if (result.status == true) {
            toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });

        } else {
            if (result.hasOwnProperty('errors')) {
                if (result.errors.hasOwnProperty('email')) {
                    toast.info(result.errors.email[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                }
            } else {
                toast.info(result.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        }
    }

    useEffect(() => {
        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);
    }, []);


    return (
        <>
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-12 col-md-12 col-sm-12">
                        <div class="card">
                            <div class="card-body">
                                <div className="mm d-flex">Your Referral Link </div><hr />

                                <div className="col-12 col-md-9 float-left">
                                    <div className="d-flex">
                                        <div className="card-body link referral_link lw p-0 m-0 mt-2 mb-1" >{props.link}</div>  
                                        {/* {(users?.referral_link)} */}
                                    </div>
                                    <input name="email" value={email} type="email" className="form-control form-control-sm mt-3 fc w-100"
                                        onChange={(e) => setEmail(e.target.value)} placeholder="Beneficiary Email Address" required />
                                </div>
                                <div className="col-12 col-md-3  float-right display-inline ">
                                    <button onClick={setCopied} className="btn btn-outline-secondary btn-md but copylink w-100">Copy Link</button><br></br>
                                    <button type="button" onClick={sent} className="btn btn-primary btn-md but w-100 ">Send Invitation</button>
                                    <ToastContainer />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ReferralLink;