import React from "react";
import ForgotPassword from "../Components/ForgotPassword";

const ResetPassword = () => {
    return (
        <>
        <div className="container-fluid" style={{ backgroundColor: "#4e73df" }}>
            <div className="row">
                <div className="col-12 col-md-12 col-sm-12">
                <ForgotPassword />
                </div>
            </div>
        </div>
        </>
    )
}
export default ResetPassword;