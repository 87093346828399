import React from "react";
import "react-treeview/react-treeview.css";
import TreeView from "react-treeview";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
const URL1 = "https://cors-anywhere.herokuapp.com/https://dev.royaltrading.world/api/app/user";
const URL2 = "https://cors-anywhere.herokuapp.com/https://dev.royaltrading.world/api/app/network/team/";

const Tree = () => {
    const [item, setItem] = useState([]);
    const [access_token, setaccess_token] = useState('');
    const [username, setUsername] = useState([]);
    const [data, setData] = useState([]);

    const getItem2 = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL1}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }

    const getdata2 = async () => {
        let response = await getItem2();
        console.log(response) //done

        setItem(response.data.UserDetails);  //done
        console.log(item)      //done
    }

    useEffect(() => {
        if (item.length == 0){
            getdata2();
        }
        
        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);
    }, []);

    const getItem = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL2}` + item.username,   //need to check
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }

    const getdata = async () => {
        let response = await getItem();
        setData(response.data.data);

        // setId(item?.id)
        // for (let i = 0; i <= item.length; i++) {
        //     if (item?.children_status === true) {
        //         setURL(URL + (item?.id))
        //     }
        // }
    }

    useEffect(() => {
        getdata();
        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);
    }, []);

    return (
        <>
            <div>
                <ul type="none">
                    {
                        data.map((data, index) => {
                            return (
                                <a className="text-decoration-none"><li className={data?.a_attr.class} id={data?.id} key={index} value={data.id}>{data.text}</li></a>
                            )
                        })
                    }
                </ul>
            </div>
        </>
    )
}
export default Tree;