import React from "react";
import UserAdmin from "../../Components/Dashboard/UserAdmin";
import DataTable from "react-data-table-component";
import { useState,useEffect} from "react";
import axios from 'axios';
const URL = "https://cors-anywhere.herokuapp.com/https://dev.royaltrading.world/api/app/member/user-token-transaction";

const ConfigurationSettings = () => {
    const [item, setItem] = useState([]);
    const [filteredItem, setFilteredItem] = useState([]);
    const [access_token, setaccess_token] = useState('');

    const getItem = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }
    const getdata = async () => {
        let response = await getItem();
        console.log(response);
        setItem(response.data.depositRequest.data);
        setFilteredItem(response.data.depositRequest.data);
        console.log(item)

    }
    const columns = [
        {
            name: <h6>Date</h6>,                   
            selector: row =>row.updated_at,              
            sortable: true,
          
        },
        {
            name: <h6>Referance No</h6>,
            selector: row => row.transaction_id,               
            sortable: true,
        },
        {
            name: <h6>Amount</h6>,
            selector: row =>"BNB  "+ row.amount,              
            sortable: true,
        },
        {
            name: <h6>Received Amount</h6>,
            selector: row => "$  "+row.received_amount,            
            sortable: true,
        },
        {
            name: <h6>Status</h6>,
            cell: (row) => (<div> {row.status == 1 ? (<span >Approved</span>) : (<span>Pending</span>)} </div>),
            selector: row => row.status,           
            sortable: true,
        },
    ]

    useEffect(() => {
        // setTimeout(() => {
        //     getdata();
        // }, 1000);

        getdata();

        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);

    }, []);

    return (
        <>
            <UserAdmin
            
                card2={
                    <div className="col-12 col-md-12 card ml-4 mri mt-4">
                    <DataTable
                        title={<h5 className="mt-4">Manage Member</h5>}
                        columns={columns}
                        data={filteredItem}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="600px"
                        selectableRow
                        selectableRowsHighlight
                        highlightOnHover
                        actions={
                            <button className="btn btn-md btn-primary mt-3">Export</button>
                        }
                    />
                    </div>
                } />
           
        </>
    )
}
export default ConfigurationSettings;