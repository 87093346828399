import React from "react";

const Approvewithdrawal = () => {
    return (
        <>
            <div className="card ml-4 mr-4 mb-5 mt-5">
                <div className="card-header">Manage Member</div>
                <form className="ml-5 mr-5 mt-4 mb-5">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label for="inputEmail4">User ID</label>
                            <input type="text" className="form-control" id="inputEmail4" placeholder="User ID" />
                        </div>
                        <div className="form-group col-md-6">
                            <label for="inputEmail4">Withdrawal Wallet</label>
                            <select id="inputState" className="form-control">
                                <option selected>All</option>
                                <option>Withdrawal Wallet</option>
                            </select>

                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label for="inputEmail4">Withdraw Request Between</label>
                            <div className="d-flex">
                                <input type="date" className="form-control" id="inputEmail4" placeholder="Keyword" />
                                <input type="date" className="form-control" id="inputEmail4" placeholder="Keyword" />
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <label for="inputPassword4">Withdraw Approve Between</label>
                            <div className="d-flex">
                                <input type="date" className="form-control" id="inputEmail4" placeholder="Keyword" />
                                <input type="date" className="form-control" id="inputEmail4" placeholder="Keyword" />
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label for="inputEmail4">Status</label>
                            <select id="inputState" className="form-control">
                                <option selected>All</option>
                                <option>Pending</option>
                                <option>Approved</option>
                                <option>Declined</option>
                            </select>
                        </div>
                        <div className="form-group col-md-6 mt-2">
                            <button type="button" className="btn btn-primary btn-block mt-4">Search</button>
                        </div>
                    </div>

                </form>
            </div>

        </>
    )
}
export default Approvewithdrawal;
