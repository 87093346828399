import React from "react";
import { CSVLink } from "react-csv";
import UserAdmin from "../Components/Dashboard/UserAdmin";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
const URL = "https://cors-anywhere.herokuapp.com/https://dev.royaltrading.world/api/app/wallet/transaction";



const AccountStatement = () => {
    const [item, setItem] = useState([]);
    const [filteredItem, setFilteredItem] = useState([]);
    const [access_token, setaccess_token] = useState('');

    const getItem = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }

    const getdata = async () => {
        let response = await getItem();
        console.log(response);
        setItem(response.data.transactions.data);
        setFilteredItem(response.data.transactions.data);
        console.log(item)
    }

    const columns = [
        {
            name: <h6>#Transaction</h6>,
            selector: row => row.code,
            sortable: true,
        },
        {
            name: <h6>Date</h6>,
            selector: row => row.created_at,
            sortable: true,
        },
        {
            name: <h6>Type</h6>,
            selector: row => row.type,
            sortable: true,
        },
        {
            name: <h6>Source</h6>,
            selector: row => row.source,
            sortable: true,
        },
        {
            name: <h6>Wallet</h6>,
            selector: row => row.wallet,
            sortable: true,
        },
        {
            name: <h6>Description</h6>,
            selector: row => row.description,
            sortable: true,
        },
        {
            name: <h6>Status</h6>,
            selector: row => row.status,
            sortable: true,
        },
        {
            name: <h6>Amount ($)</h6>,
            selector: row => row.amount,
            sortable: true,
        },
        {
            name: "Action",
            cell: (row) => <button className="btn btn-primary" onClick={() => alert(row.id)}>Edit</button>
        },
    ]

    const csvLink = {
        filename: "Referrals.csv",
        headers: columns,
        data: item
    }

    useEffect(() => {
        setTimeout(() => {
            getdata();
        }, 1000);

        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);

    }, []);

    return (
        <>
            <UserAdmin
                // card1={
                //     <CSVLink {...csvLink} className="btn btn-md btn-primary mt-3">Export</CSVLink>
                // }
                card2={
                    <div className="col-12 col-md-12 card ml-4 mri mt-4">
                        <DataTable
                            title={<h5 className="mt-4">Account Statement</h5>}
                            columns={columns}
                            data={filteredItem}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight="600px"
                            selectableRow
                            selectableRowsHighlight
                            highlightOnHover
                            actions={
                                <CSVLink data={item} filename="Account_Statement.csv">
                                <button className="btn btn-md btn-primary mt-3">Export</button>
                                </CSVLink>
                            }
                        />
                    </div>
                }
            />

        </>
    )
}
export default AccountStatement;