import React from "react";

const Referralsettings = () => {
    return (
        <>
            <div className="row ml-4 mr-4 mt-5 mb-5">

                <div className="col-sm-2">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title border p-1">Level 1 (%)	</h5>
                            <p className="card-text">With </p>
                            <a href="#" className="btn btn-primary">Go somewhere</a>
                        </div>
                    </div>
                </div>

                <div className="col-sm-2">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Level 2 (%)</h5>
                            <p className="card-text">With</p>
                            <a href="#" className="btn btn-primary">Go somewhere</a>
                        </div>
                    </div>
                </div>

                <div className="col-sm-2">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Level 3 (%)</h5>
                            <p className="card-text">With</p>
                            <a href="#" className="btn btn-primary">Go somewhere</a>
                        </div>
                    </div>
                </div>

                <div className="col-sm-2">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Level 4 (%)</h5>
                            <p className="card-text">With.</p>
                            <a href="#" className="btn btn-primary">Go somewhere</a>
                        </div>
                    </div>
                </div>

                <div className="col-sm-2">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Level 5 (%)</h5>
                            <p className="card-text">With.</p>
                            <a href="#" className="btn btn-primary">Go somewhere</a>
                        </div>
                    </div>
                </div>

                <div className="col-sm-2">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Level 6 (%)</h5>
                            <p className="card-text">With.</p>
                            <a href="#" className="btn btn-primary">Go somewhere</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row ml-4 mr-4 mt-5 mb-5">
                <div className="col-sm-2">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Level 7 (%)</h5>
                            <p className="card-text">With.</p>
                            <a href="#" className="btn btn-primary">Go somewhere</a>
                        </div>
                    </div>
                </div>

                <div className="col-sm-2">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Level 8 (%)</h5>
                            <p className="card-text">With.</p>
                            <a href="#" className="btn btn-primary">Go somewhere</a>
                        </div>
                    </div>
                </div>

                <div className="col-sm-2">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Level 9 (%)</h5>
                            <p className="card-text">With.</p>
                            <a href="#" className="btn btn-primary">Go somewhere</a>
                        </div>
                    </div>
                </div>
                
                <div className="col-sm-2">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Level 10 (%)</h5>
                            <p className="card-text">With.</p>
                            <a href="#" className="btn btn-primary">Go somewhere</a>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </>
    )
}
export default Referralsettings;
