import './App.css';
import React from 'react';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import Dashboard from "./Main/Dashboard"
import AccountStatement from './Main/AccountStatement';
import Deposit from './Main/Deposit';
import List from "./Main/List";
import DirectReferrals from "./Main/DirectReferrals";
import LevelDetails from "./Main/LevelDetails";
import PurchaseYourPackage from "./Main/PurchaseYourPackage";
import RankDetails from "./Main/RankDetails";
import Transactions from "./Main/Transactions";
import UnilevelTree from "./Main/UnilevelTree";
import Withdraw from "./Main/Withdraw";
import Signin from './Main/Signin';
import BalanceReport from "./Main/admin/BalanceReport";
import ConfigurationSettings from "./Main/admin/ConfigurationSettings";
import ManageMember from "./Main/admin/ManageMember";
import MemberAccStatement from "./Main/admin/MemberAccStatement";
import MemberSubscription from "./Main/admin/MemberSubscription";
import MemberTransactions from "./Main/admin/MemberTransactions";
import PackageSettings from "./Main/admin/PackageSettings";
import ReferralSettings from "./Main/admin/ReferralSettings";
import UnilevelSettings from "./Main/admin/UnilevelSettings";
import WithdrawalApprovals from "./Main/admin/WithdrawalApprovals";
import Register from './Main/Register';
import ResetPassword from './Main/ResetPassword';
import Profile from './Main/Profile';
import ResetMailPassword from './Components/ResetMailPassword';

function App() {
  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route exact path="/reset-password-mail" element={<ResetMailPassword/>}>Home</Route>
        <Route exact path="/profile" element={<Profile/>}>Home</Route>
        <Route exact path="/" element={<Dashboard/>}>Home</Route>
        <Route exact path="/dashboard" element={<Dashboard/>}>Dashboard</Route>
        <Route exact path="/login" element={<Signin/>}>Login</Route>
        <Route exact path="/signup" element={<Register/>}>Login</Route>
        <Route exact path="/reset-password" element={<ResetPassword/>}>Login</Route>
        <Route exact path="/deposit" element={<Deposit/>}>Deposit</Route>
        <Route exact path="/withdraw" element={<Withdraw/>}>Withdraw</Route>
        <Route exact path="/transactions" element={<Transactions/>}>Transactions</Route>
        <Route exact path="/account-statement" element={<AccountStatement/>}>Account Statement</Route>
        <Route exact path="/purchase-your-package" element={<PurchaseYourPackage/>}>Purchase Your Package</Route>
        <Route exact path="/unilevel-tree" element={<UnilevelTree/>}>Unilevel Tree</Route>
        <Route exact path="/direct-referrals" element={<DirectReferrals/>}>Direct Referrals</Route>
        <Route exact path="/level-details" element={<LevelDetails/>}>Level Details</Route>
        <Route exact path="/list" element={<List/>}>List</Route>
        <Route exact path="/ranks-details" element={<RankDetails/>}>Rank Details</Route>
        <Route exact path="/balance-report" element={<BalanceReport/>}>Home</Route>
        <Route exact path="/configuration-settings" element={<ConfigurationSettings/>}>Home</Route>
        <Route exact path="/manage-member" element={<ManageMember/>}>Home</Route>
        <Route exact path="/member-accStatement" element={<MemberAccStatement/>}>Home</Route>
        <Route exact path="/member-subscription" element={<MemberSubscription/>}>Home</Route>
        <Route exact path="/member-transactions" element={<MemberTransactions/>}>Home</Route>
        <Route exact path="/package-settings" element={<PackageSettings/>}>Home</Route>
        <Route exact path="/referral-settings" element={<ReferralSettings/>}>Home</Route>
        <Route exact path="/unilevel-settings" element={<UnilevelSettings/>}>Home</Route>
        <Route exact path="/withdrawal-approvals" element={<WithdrawalApprovals/>}>Home</Route>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
