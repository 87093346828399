import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
import "../css/referral.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const URL = "https://cors-anywhere.herokuapp.com/https://dev.royaltrading.world/api/app/profile/send-otp";
const URL2 = "https://cors-anywhere.herokuapp.com/https://dev.royaltrading.world/api/app/wallet/withdraw/cash";

const WithdrawBalance = (props) => {
    const [camount, setCamount] = useState();
    const [access_token, setaccess_token] = useState();
    const [item, setItem] = useState();
    const [item2, setItem2] = useState();
    const [email_otp, setEmail_otp] = useState();

    const [withdraw, setWithdraw] = useState();
    const [cwallet, setCwallet] = useState("withdraw");



    const user = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.get(`${URL}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result.data;
        } catch (error) {
            console.log('Error By Calling data')
        }
    }

    async function sent() {
        let item = { camount };
        console.log("item");
        // if(!item.length === 0){
        let result = await user(item)
        if (result.status == true) {
             toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        } else {
             toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }
    }

    const postData = async (data) => {
        let access_token = localStorage.getItem('access_token');
        try {
            var result = await axios.post(`${URL2}`, data, {
                headers: {
                    "Authorization": "Bearer " + access_token,
                    "Accept": "*/*",
                    'Access-Control-Allow-Methods': 'OPTIONS,POST',
                    'Access-Control-Allow-Credentials': true,
                    'Access-Control-Allow-Origin': '*',
                    'X-Requested-With': '*',
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
                "async": true,
                "crossDomain": true,
                "method": "GET",
            })
            // console.log(result)
            return result.data;
        } catch (error) {
            console.log(error)
        }
    }

    async function post() {
        let item2 = { cwallet, camount, email_otp };
        console.log(item2);
        let result = await postData(item2)

        console.log(result)
        if (result.status == true) {
             toast.info(result.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        } else {
            if (result.hasOwnProperty('errors')) {
                if (result.errors.hasOwnProperty('cwallet')) {
                     toast.info(result.errors.cwallet[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                }
                if (result.errors.hasOwnProperty('camount')) {
                     toast.info(result.errors.camount[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                }
                if (result.errors.hasOwnProperty('email_otp')) {
                     toast.info(result.errors.email_otp[0], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                }
            } else {
                 toast.info(result.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
                // console.log(result)
            }
        }
    }

    useEffect(() => {

        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);
    }, []);

    return (
        <><ToastContainer />
            <div className="container-fluid mt-3 mb-3 ">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="card">
                            <div className="card-header">
                                Withdraw Balance : $ {props.bal}
                            </div>
                            <div className="card-body">
                                <label className="btn btn-primary  btn-block">Your Current Withdraw Method is BNB and Account Address is set to {props.add}.</label>
                                <form className="mt-4">
                                    <div>
                                        <div className="col">
                                            Wallet:
                                            <select className="form-control mb-2"
                                                onChange={(e) =>setCwallet(e.target.value)}
                                                name="cwallet">
                                                <option value="withdraw">Withdraw wallet</option>
                                            </select>
                                        </div>

                                        <div className="col">
                                            Amount ($) :
                                            <input
                                                type="text"
                                                className="form-control mb-2"
                                                placeholder="Wallet :"
                                                onChange={(e) => setCamount(e.target.value)}
                                                name="camount"

                                            />
                                        </div>
                                        <div className="col">
                                            One Time Password (OTP):
                                            <input
                                                type="text"
                                                name="email_otp"
                                                className="form-control mb-2"
                                                onChange={(e) => setEmail_otp(e.target.value)}
                                            />
                                        </div>

                                        <div className="col">
                                            <button
                                                type="button"
                                                class="btn btn-primary  btn-block mb-2"
                                                onClick={sent}>Get OTP on email</button>
                                        </div>

                                        <div className="col">
                                            <button onClick={post} type="button" class="btn btn-primary btn-block">Proceed</button>
                                        </div>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default WithdrawBalance;