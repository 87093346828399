import React from "react";
import UserAdmin from "../Components/Dashboard/UserAdmin";
import DataTable from "react-data-table-component";
import { useState } from "react";
import { useEffect } from "react";
import axios from 'axios';
import RankDetail from "../Components/RankDetail";
const URL = "https://cors-anywhere.herokuapp.com/https://dev.royaltrading.world/api/app/member-rank/details";

const RankDetails = () => {
    const [item, setItem] = useState([]);
    const [filteredItem, setFilteredItem] = useState([]);
    const [access_token, setaccess_token] = useState('');

    const getItem = async () => {
        let access_token = localStorage.getItem('access_token');
        try {
            const result = await axios.get(`${URL}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + access_token
                    }
                })
            return result;
        } catch (error) {
            console.log('error by calling data')
        }
    }
    const getdata = async () => {
        let response = await getItem();
        console.log(response);
        setItem(response.data.rankDetails);
        setFilteredItem(response.data.rankDetails);
        console.log(item)

    }

    const columns = [
        {
            name: <h6>Date</h6>,                   
            selector: row => row.data,
            sortable: true,
        },
        {
            name: <h6>Username</h6>,
            selector: row => row.data,            
            sortable: true,
        },
        {
            name: <h6>Rank</h6>,
            selector: row => row.data,            
            sortable: true,
        },
        {
            name: <h6>Reward</h6>,
            selector: row => row.data,            
            sortable: true,
        },
        {
            name: <h6>Status</h6>,
            selector: row => row.data,           
            sortable: true,
        }
    ]

    useEffect(() => {
        if(item.length && filteredItem.length === 0){
            getItem();
        }

        let access_token = localStorage.getItem('access_token');
        setaccess_token(access_token);

    }, []);

    return (
        <>
            <UserAdmin
                card1={
                    <RankDetail
                    username="subham"
                    />
                }
                card2={
                    <div className="col-12 col-md-12 card ml-4 mri mt-4">
                    <DataTable
                        title={<h5 className="mt-4">Rank Details</h5>}
                        columns={columns}
                        data={filteredItem}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight="600px"
                        selectableRow
                        selectableRowsHighlight
                        highlightOnHover
                        actions={
                            <button className="btn btn-md btn-primary mt-3">Export</button>
                        }
                        />
                    </div>
                } 
            />
        </>
    )
}
export default RankDetails;