import React from "react";
import UserAdmin from "../Components/Dashboard/UserAdmin";
import Tree from "../Components/Tree";

const UnilevelTree = () => {

    return (
        <>
            <UserAdmin
                card1={
                   <Tree />
                }
             />
           
        </>
    )
}
export default UnilevelTree;
